<template>
  <div>
    <b-card no-body
      v-for="(item, key) in credits"
      :key="`credit-${key}`"
      class="sm-shadow"
      v-b-toggle="`credit-${key.replaceAll(' ', '-')}`"
    >
      <b-card-header class="header">
        <div>
          <span class="material-symbols-rounded icon">
            {{ item.icon }}
          </span>
          <p class="small-button mb-0">{{ key }}</p>
        </div>
        <span class="material-symbols-rounded arrow">
          expand_more
        </span>
      </b-card-header>
      <b-collapse
        :id="`credit-${key.replaceAll(' ', '-')}`"
        :visible="item.open"
      >
        <div class="credit-body">
          <div
            v-for="(cred, c_key) in item.credits"
            :key="`credit-key-${c_key}`"
          >
            <p class="small-button mb-0">{{ c_key }}</p>
            <p class="credit">{{ cred === "UL" ? "Unlimited" : `${cred} c.` }}</p>
          </div>
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
export default {
  data() { return {
    credits: {
      /**
       * <Category>: {      // Name of the top-level category
       *   open: [Boolean]  // [true] Make the collapsible opened by default (optional)
       *   icon: <Icon>     // Name of a valid Google Material Symbols
       *   credits: {       // list of child credit keys under the <Category>
       *     <Credit Key>: {    // Name of the key
       *       key: <Key>       // Object key used to locate the credits in `nexlaw_credits`
       *       cred: 0          // The calculated credits will be updated automatically
       *     }
       *   }
       * }
       */

      "LawBot": {
        open: true,
        icon: "smart_toy",
        credits: {
          "LawBot": {
            key: "qbot_questions",
            cred: 0,
          },
        },
      },
      "Legal AI Trial Copilot": {
        open: true,
        icon: "gavel",
        credits: {
          "Pre-trial Phase": {
            key: "les",
            cred: 0,
          },
          "Trial Phase": {
            key: "les_trial",
            cred: 0,
          },
        },
      },
      "Legal Research": {
        icon: "plagiarism",
        credits: {
          "Legal Research": {
            key: "legal_argument",
            cred: 0,
          },
          "Document Upload": {
            key: "legal_argument_documents",
            cred: 0,
          },
          "Case Summarize": {
            key: "legal_argument_summarize_discussion_case",
            cred: 0,
          },
          "Document Summarize": {
            key: "legal_argument_summarize_document",
            cred: 0,
          },
        },
      },
      "Case Law Summarizer": {
        icon: "description",
        credits: {
          "Case Law Summarizer": {
            key: "case_summarize",
            cred: 0,
          },
        },
      },
      "Case Law Search": {
        icon: "search",
        credits: {
          "Case Law Search": {
            key: "statement",
            cred: 0,
          },
          "Case Summary": {
            key: "jurisdiction_summarizing",
            cred: 0,
          },
        },
      },
      "Legislation Search": {
        icon: "quick_reference_all",
        credits: {
          "Legislation Search": {
            key: "legislation",
            cred: 0,
          },
          "Legislation Summary": {
            key: "legislation_summarizing",
            cred: 0,
          },
        },
      },
      "Legal Compose": {
        icon: "edit_document",
        credits: {
          "Legal Writer": {
            key: "lod",
            cred: 0,
          },
          "Legal Writer Summary": {
            key: "summarize_lod",
            cred: 0,
          },
          "Contract Review": {
            key: "contract_review",
            cred: 0,
          },
          "Contract Improvement": {
            key: "contract_improvments", // `improvements` will not work as there is typo in the data
            cred: 0,
          },
        },
      },
    }
  }},

  created() {
    const { full, used } = JSON.parse(localStorage.userInfo).nexlaw_credits;

    // eslint-disable-next-line
    Object.entries(this.credits).forEach(([_, { credits }]) => {
      Object.keys(credits).forEach(key => {
        credits[key] = full[credits[key].key] === "unlimited"
          ? "UL"
          : full[credits[key].key] - used[credits[key].key];
      });
    });
  },
}
</script>

<style scoped>
.card {
  margin-top: 10px;
  overflow: hidden;
}

.header {
  padding: 12px 16px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-bottom: 0px;
}

.header div {
  display: flex;
  align-items: center;
}

.icon {
  padding: 4px;
  font-size: 16px;
  border-radius: 100%;
  background-color: var(--primary);
  color: white;
  margin-right: 10px;
}

.credit-body {
  padding: 0px 16px 12px 16px;
}

.credit-body div {
  display: flex;
  justify-content: space-between;
}

.credit-body p {
  color: var(--dark-grey) !important;
  margin-left: 34px;
  align-content: center;
}

.credit-body .credit {
  margin: 0px 0px 0px 10px;
  color: var(--status-blue) !important;
  font-weight: 600;
  text-wrap: nowrap;
}
</style>