<!--
  [pending]
  - Refer button: pending Design
  - View File/Bookmark button: pending Design

  - unknown bug when toggle dropdown menu in table:
    the dropdown menu will stuck and no able to be toggle off
-->
<template>
  <div @click="hideAllDropdown">
    <span id="bg-banner"></span>

    <div id="Statistic" class="mb-4">
      <h2>Good morning, {{ userName | username }}</h2>
      <p>Good to see you again! Review your recent campaigns and remaining credits.</p>

      <Statistic :loading="statLoading" />
    </div>

    <div class="d-flex w-100" style="margin-top: 20px;">
      <div id="CaseFile">
        <h4 class="mb-3">Recent Campaigns</h4>
        <TableList emptyText="Looks like it's empty here. Time to add some cases!" :load-data="loadData" :loading="tableLoading"
          :tableConfig="tableConfig" :tableData="tableData" :actions="tableHandler" @sort-data="sortData" @row-click="navigateToCampaign" />
      </div>

      <!-- Hide temporarily -->
      <!-- <div id="NexLawCredit">
        <h4 class="mb-3">Remaining Credits</h4>
        <NexLawCredits />
      </div> -->
    </div>

    <!-- Delete Modal -->
    <b-modal id="delete-modal" hide-header hide-footer centered>
      <div class="delete-prompt">
        <img src="@/assets/misc/delete.svg" alt="Delete" />
        <p>
          <b>Are you sure you want to delete this campaign?</b><br>
          This action is permanent and cannot be undone.
        </p>
      </div>
      <div class="delete-prompt-options">
        <button class="btn-outlined" @click="$bvModal.hide('delete-modal')">
          Cancel
        </button>
        <button class="btn-danger" @click="deleteCampaign">Delete</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import Statistic from "./main/Statistic.vue";
import NexLawCredits from "./main/NexLawCredits.vue";
import TableList from "../../components/misc/TableList.vue";
import general from "../../store/general";

export default {
  components: {
    Statistic,
    NexLawCredits,
    TableList,
  },
  data() {
    return {
      // Overview Header
      userName: "",
      statLoading: true,

      // Recent Campaigns
      tableLoading: true,
      tableConfig: {
        format: [
          {
            header: "ID",
            width: 5,
            button: true,
          },
          {
            header: "Type",
            width: 16,
            button: true,
          },
          {
            header: "Campaign Info",
            // width: 25,
            button: true,
          },
          {
            header: "Created By",
            width: 16,
            center: true,
          },
          {
            header: "Date Created",
            width: 15,
            center: true,
            button: true,
          },
          {
            header: "Action",
            width: 8,
            center: true,
          },
        ],
        options: [
          {
            icon: "arrow_forward",
            label: "View Campaign",
          },
          {
            icon: "star",
            label: "Bookmark",
          },
          {
            icon: "archive",
            label: "Archive",
          },
          {
            icon: "delete",
            label: "Delete Campaign",
          },
        ],
        pagination: {
          current: 1,
          total: 0,
        },
      },
      fullData: [],
      tableData: [],

      // Dropdown Menu
      selected: null,
    }
  },

  watch: {
    // update route when changing page
    tableConfig: {
      handler(newCf) {
        const page = newCf.pagination.current;
        if (this.$route.query.page == page) return;
        this.$router.push({ query: { page } });
      },
      deep: true,
    },

    // update data when changed, works when using backward navigation
    $route() { this.loadData(true); }
  },

  methods: {
    // to hide all dropdown menu when clicked on anywhere in the page
    hideAllDropdown() { this.$root.$emit('bv::hide::tooltip'); },

    sortData(data) { this.tableData = data; },

    getCampaignInfo(entry) {
      switch (entry.type) {
        case "les": return entry.client_case;

        case "argument": case "memo":
          return entry.statement;

        case "case_summarizer": case "contract_review": return entry.file_name;

        case "statement": case "legislation":
          return entry.statement
            .replaceAll(`["`, ``)
            .replaceAll(`"]`, ``);

        case "LOD":
          if (entry.letter_type == "custom") return entry.info.prompt;
          return this.$options.filters.legalWriter_composeType(entry.compose_type);

        case "chatbot":
          return entry.title;
      }
    },

    tableHandler(o, id) {
      this.selected = this.tableData[id][0];
      const campaignId = this.tableData[id][0];
      const campaignType = this.tableData[id][5];

      switch (o) {
        // View File
        case 0:
          this.viewCampaign(campaignId, campaignType);
          break;

        // Bookmark
        case 1:
          this.bookmarkCampaign();
          break;

        // Archive
        case 2:
          this.archiveCampaign();
          break;

        // Delete
        case 3:
          this.$bvModal.show("delete-modal");
          break;
      }
    },

    navigateToCampaign(id) {
      const campaignId = id;
      const campaignType = this.tableData.filter((item) => item[0] === id)[0][5];
      this.viewCampaign(campaignId, campaignType);
    },

    viewCampaign(campaignId, campaignType) {
      let routeName = '';
      let category = '';

      switch (campaignType) {
        case 'les':
          routeName = 'LegalCopilot_CaseFlowResult';
          break;
        case 'argument':
          routeName = 'LegalAdvice_ArgumentResult';
          category = 'Legal Argument';
          break;
        case 'memo':
          routeName = 'LegalAdvice_MemoResult';
          category = 'Legal Memo';
          break;
        case 'case_summarizer':
          routeName = 'CaseLawSummarizer_Case';
          break;
        case 'statement':
          routeName = 'CaseLawSearchResult';
          break;
        case 'legislation':
          routeName = 'LegislationSearchResult';
          break;
        case 'chatbot':
          routeName = 'ChatNeXa';
          break;
        case 'nexa_deep_research':
          routeName = 'DeepResearchResult';
          break;
        case 'nexa_compare_jurisdictions':
          routeName = 'CompareJuriResult';
          break;
        case 'nexa_find_precedents':
          routeName = 'FindPrecedentResult';
          break;
        case 'nexa_analyze_pleadings':
          routeName = 'AnalyzePleadingResult';
          break;
        case 'nexa_document_insights':
          routeName = 'DocumentInsightResult';
          break;
        case 'nexa_build_argument':
          routeName = 'BuildArgumentResult'
          break;
        case 'nexa_build_memo':
          routeName = 'BuildMemoResult';
          break;
        default:
          break;
      }

      const params = {
        id: campaignId
      };
      if (category) params['category'] = category;

      this.$router.push({
        name: routeName,
        params: params
      });
    },

    bookmarkCampaign() {
      general.BookmarkCampaign(this.selected).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
        this.loadData();
      });
    },  

    archiveCampaign() {
      general
        .ArchiveCampaign(this.selected)
        .then(res => {
          if (res.data.message === "Successful") this.$toast.success(res.data.message);
          this.loadData();
        });
    },

    deleteCampaign() {
      this.$bvModal.hide("delete-modal");
      general
        .DeleteCampaign(this.selected)
        .then(res => {
          if (res.data.message === "Successful") this.$toast.success(res.data.message);
          this.loadData();
        });
    },

    loadData() {
      const page = this.tableConfig.pagination.current;
      this.tableLoading = true;
      general
        .GetCampaigns({ page })
        .then(res => {
          if (!res) return;
          const DATA = res.data.data;

          this.tableConfig.pagination = {
            current: DATA.current_page,
            total: DATA.total,
            perPage: DATA.per_page,
          };
          this.fullData = DATA.data;
          this.statLoading = false;
          this.tableLoading = false;

          this.tableData = this.fullData.map(ent => [
            ent.id,
            this.$options.filters.feature(ent.type),
            this.getCampaignInfo(ent),
            this.$options.filters.username(ent.creator.name),
            ent.created_at,
            ent.type
          ]);
        })
        .catch(() => console.log("Failed to fetch data."));
    },
  },

  created() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userName = userInfo.name;

    if (!this.$route.query.page) this.$router.push({ query: { page: 1 } });
    else this.loadData();
  },
};
</script>

<style scoped>
.page-content {
  position: relative;
}

#Statistic {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#CaseFile {
  width: 100%;
  /* width: calc(70% - 10px); */
  /* margin-right: 20px; */
}

#NexLawCredit {
  width: calc(30% - 10px);
}
</style>

<!-- Overview's Header -->
<style scoped>
#bg-banner {
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 200px;
  z-index: 0;
  background-color: var(--dark-blue);
}

h2 {
  margin-bottom: 0px;
  color: white !important;
  z-index: 1;
}

h2+p {
  margin-bottom: 30px;
  color: white !important;
  z-index: 1;
}
</style>

<!-- Delete Modal -->
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt img {
  background-color: var(--interface-grey);
  padding: 32px;
  border-radius: 8px;
  height: 164px;
  width: 100%;
  margin-bottom: 16px;
}

.delete-prompt p {
  margin-bottom: 0px;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
</style>
