<!--
  This component is *NOT* Scalable and Flexible
  manual modification is required for new feature
-->
<template>
  <div class="Stat-container">
    <div id="circle-graph" class="sm-shadow">
      <span v-if="loading" class="loading-graph"></span>

      <template v-else>
        <div class="circle" :style="graphStyle">
          <div :class="['content', !campaignCount && 'empty']">
            <h2>{{ campaignCount }}</h2>
            <p>Campaigns</p>
          </div>
        </div>
        <p>Campaigns Created</p>
      </template>
    </div>

    <div id="stats-summary" :class="loading && 'loading'">
      <div
        v-for="(row, r_id) in displayFormat"
        :key="`stats-row-${r_id}`"
        class="stat-row"
        :style="{
          'margin-top': r_id > 0 ? '20px' : '0px'
        }"
      >
        <div
          v-for="(val, index) in Object.entries(row)"
          :key="`stats-card-${r_id}-${index}`"
          :class="['stat-card sm-shadow', val[1][1]]"
          @mouseenter="handleHover(1, r_id, index)"
          @mouseleave="handleHover(0)"
        >
          <div v-if="loading" class="loading-graph shift"></div>

          <template v-else>
            <span class="material-symbols-rounded">{{ val[1][0] }}</span>
            <h2>{{ Object.values(data)[gridToIndex(r_id, index)][0] }}</h2>
            <h5>{{ val[0] }}</h5>
            <p v-show="Object.values(data)[gridToIndex(r_id, index)][0] > 0">{{ Object.values(data)[gridToIndex(r_id, index)][1] }} new today</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * determine table's state
     * [true] loading state
     * [false] display state
     */
    loading: { type: Boolean, },
  },

  data() { return {
    hover: null,
    data: {},
    displayFormat: [
      /**
       * { // each row is encapsulated in a object
       * 
       *  <Feature>: [  // Feature's display name
       *    [0] <Icon>  // Name of a valid Google Material Symbol
       *    [1] <Color> // Color class name used throughout this component
       *                   ** need to manually add CSS styling in color config
       *  ]
       * }
       */

      {
        "Legal AI Trial Copilot": [
          "gavel", "blue"
        ],
        "Legal Advice": [
          "plagiarism", "orange"
        ],
        "Case Law Summarizer": [
          "description", "red"
        ],
        "Legal Research": [
          "quick_reference_all", "green"
        ]
      },
      // {
      //   "Case Law Search": [
      //     "search", "grey"
      //   ],
      //   "Legislation Search": [
      //     "quick_reference_all", "green"
      //   ],
      //   "Legal Compose": [
      //     "edit_document", "purple"
      //   ],
      // }
    ],
  }},

  computed: {
    campaignCount() {
      return Object
        .values(this.data)
        .reduce((acc, curr) => acc + curr[0], 0);
    },

    graphStyle() {
      /**
       * color theme config
       * required manual update
       */
      const ColorMap = [
        "#1890FF", "#FFA319", "#F05757", "#52C41A"
      ];

      const partition = [];
      var number = 0;

      if (this.campaignCount > 0) {
        Object.values(this.data).forEach((stat, id) => {
          const num = stat[0] * 100 / this.campaignCount;
          const color = this.hover !== null && (this.hover !== id)
            ? `rgba(${this.hexToRgb(ColorMap[id])}, 0.2)`
            : ColorMap[id];

          partition.push(`${color} ${number}% ${number + num}%`);
          number += num;
        })
        partition.push(`rgba(0, 0, 0, 0.1) ${number}% 100%`);

        return { background: `conic-gradient(${partition.join(', ')})` };
      }

      // diplay for empty data
      else return { background: `var(--mid-grey)` }
    },
  },

  methods: {
    gridToIndex(row, col) { return ((3 * row) + col); },

    hexToRgb(hex) {
      const int = parseInt(hex.slice(1), 16);
      return `${(int >> 16) & 255}, ${(int >> 8) & 255}, ${int & 255}`;
    },

    handleHover(flag, row = null, col = null) {
      this.hover = flag === 1 ? this.gridToIndex(row, col) : null;
    },

    updateData() {
      const CRED = JSON.parse(localStorage.getItem("userInfo")).nexlaw_credits,
          USED = CRED.used,
          NEW = CRED.daily;

      this.data = {
        "Legal AI Trial Copilot": [USED.les, NEW.les],
        "Legal Advice": [USED.legal_argument, NEW.legal_argument],
        "Case Law Summarizer": [USED.case_summarize, NEW.case_summarize],
        "Legal Research": [USED.statement + USED.legislation, NEW.statement + NEW.legislation],
        // "Case Law Search": [USED.statement, NEW.statement],
        // "Legislation Search": [USED.legislation, NEW.legislation],
        // "Legal Compose": [USED.lod + USED.contract_review, NEW.lod + NEW.contract_review],
      };
    },
  },

  created() { this.updateData(); },
}
</script>

<style scoped>
.Stat-container {
  height: calc(100% - 52px);
  display: flex;
  z-index: 1;
}

#circle-graph {
  max-width: 30%;
  flex-grow: 1;
  align-content: center;
  background-color: white;
  border-radius: 16px;
  padding: 15px 0px;
}

.circle {
  width: 196px;
  height: 196px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0px auto 15px auto;
}

.content {
  width: 156px;
  height: 156px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
}

.content.empty h2 {
  color: var(--mid-grey) !important;
}

.content.empty p {
  color: var(--mid-grey) !important;
}

.content h2 {
  margin-bottom: 0px;
  font-weight: 700;
}

.content p {
  margin-bottom: 0px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: var(--dark-grey) !important;
}

#circle-graph p {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}

#stats-summary {
  margin-left: 20px;
  flex-grow: 1;
}

#stats-summary .stat-row {
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.stat-row .stat-card {
  margin-bottom: 0px;
  width: calc(25% - 15px);
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid transparent;
}

/* add a white background to cards to compensate the RGBA color */
.stat-row .stat-card::before {
  content: "";
  display: flex;
  position: absolute;
  margin: -15px 0px 0px -15px;
  width: calc(25% - 15px);
  height: 100%;
  background-color: white;
  border-radius: 8px;
  z-index: -1;
}

.stat-card div {
  display: flex;
  justify-content: space-between;
}

.stat-card .material-symbols-rounded {
  padding: 8px;
  border-radius: 6px;
}

.stat-card h2 {
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 20px;
}

.stat-card h5 {
  margin-top: 15px;
  margin-bottom: 0px;
}

.stat-card p {
  margin-top: 5px;
  margin-bottom: 0px;
  color: var(--dark-grey) !important;
}
</style>

<!-- loading -->
<style scoped>
.loading-graph {
  width: 100%;
  height: 100%;
  min-height: calc(20vh - 64px);
  display: flex;
  flex-grow: 1;
  border-radius: 16px;
  background-color: #F3F3F3;
}

.loading-graph.shift {
  margin: -15px 15px 15px -15px;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  border-radius: 10px;
}

.stat-card:has(.loading-graph.shift) {
  border-color: #F3F3F3 !important;
}
</style>

<!-- Color Config for Cards -->
<style scoped>
.blue:hover {
  background-color: rgba(16, 137, 255, 0.1);
  color: #1890FF;
  border-color: #1890FF;
}
.blue:hover h5,.blue:hover h2{
  color: #1890FF !important;
}
.blue:hover p {
  color: #1890FF !important; 
}
.blue span {
  background-color: rgba(16, 137, 255, 0.1);
  color: #1890FF;
}

.orange:hover {
  background-color: rgba(255, 163, 25, 0.1);
  color: #FFA319;
  border-color: #FFA319;
}
.orange:hover h5, .orange:hover h2 {
  color: #FFA319 !important;
}
.orange:hover p {
  color: #FFA319 !important; 
}
.orange span {
  background-color: rgba(255, 163, 25, 0.1);
  color: #FFA319;
}

.red:hover {
  background-color: rgba(240, 87, 87, 0.1);
  color: #F05757;
  border-color: #F05757;
}
.red:hover h5, .red:hover h2 {
  color: #F05757 !important;
}
.red:hover p {
  color: #F05757 !important; 
}
.red span {
  background-color: rgba(240, 87, 87, 0.1);
  color: #F05757;
}

.grey:hover {
  background-color: rgba(15, 50, 82, 0.1);
  color: #000;
  border-color: #000;
}
.grey:hover h5, .grey:hover h2 {
  color: #000 !important;
}
.grey:hover p {
  color: #000 !important; 
}
.grey span {
  background-color: rgba(15, 50, 82, 0.1);
  color: #000;
}

.green:hover {
  background-color: rgba(99, 225, 202, 0.1);
  color: #52C41A;
  border-color: #52C41A;
}
.green:hover h5, .green:hover h2 {
  color: #52C41A !important;
}
.green:hover p {
  color: #52C41A !important; 
}
.green span {
  background-color: rgba(99, 225, 202, 0.1);
  color: #52C41A;
}

.purple:hover {
  background-color: rgba(195, 25, 255, 0.1);
  color: #C319FF;
  border-color: #C319FF;
}
.purple:hover h5, .purple:hover h2 {
  color: #C319FF !important;
}
.purple:hover p {
  color: #C319FF !important; 
}
.purple span {
  background-color: rgba(195, 25, 255, 0.1);
  color: #C319FF;
}
</style>